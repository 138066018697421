window.addEventListener("scroll", () => {
  document.documentElement.style.setProperty(
    "--scroll-y",
    `${window.scrollY}px`
  );
});

const body = document.body;

export function openModal() {
  const scrollY = document.documentElement.style.getPropertyValue("--scroll-y");
  body.style.position = "fixed";
  body.style.top = `-${scrollY}`;
}

export function closeModal() {
  const scrollY = body.style.top;
  body.style.position = "";
  body.style.top = "";
  window.scrollTo(0, parseInt(scrollY || "0") * -1);
}
