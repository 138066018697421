import {
  openNav,
  closeNav,
  openSearch,
  closeSearch,
} from "./modules/navigation.js";
import initialize from "./modules/map.js";

// Stop transitions before page has loaded
$(window).on("load", function () {
  $("body").removeClass("preload");
});

// Toggle navigation
$("#hamburger").click(function (e) {
  e.preventDefault();
  $("#main-navigation").hasClass("hidden") ? openNav() : closeNav();
});

$("#search").click(function (e) {
  e.preventDefault();
  $(".search-results").hasClass("hidden") ? openSearch() : closeSearch();
});

// Reset if browser is resized
$(window).resize(function () {
  if ($(window).width() >= 768) {
    closeNav();
  }
});

// Detect user scroll and show/hide nav
var lastScrollTop = 0;
var delta = 20;

$(window).scroll(function () {
  var nowScrollTop = $(this).scrollTop();

  if (nowScrollTop > 75) {
    if (Math.abs(lastScrollTop - nowScrollTop) >= delta) {
      if (nowScrollTop > lastScrollTop) {
        // Scroll down
        $("body > header, .subheader").addClass("-translate-y-16");
        $(".no-subheader").addClass("-translate-y-full");
      } else {
        // Scroll up
        $("body > header, .subheader").removeClass("-translate-y-16");
        $(".no-subheader").removeClass("-translate-y-full");
      }
      lastScrollTop = nowScrollTop;
    }
  }
});

// Scroll to top on 'skip to content' focus
$('a[href="#main"]').focus(function () {
  window.scrollTo({ top: 0, behavior: "smooth" });
});

$("body > header a").focus(function () {
  $("body > header, .subheader").removeClass("-translate-y-16");
  $(".no-subheader").removeClass("-translate-y-full");
});

// BTT button
$(".btt-btn").click(function () {
  $("html, body").scrollTop(0);
  $("body > header, .subheader").removeClass("-translate-y-16");
  $(".no-subheader").removeClass("-translate-y-full");
});

// Toggle table rows
$(document).on("click", ".toggle-row", function () {
  $(this).parent().parent().next(".desc").toggleClass("hidden");
});

// Page scroll anchor links
var currentHash = "#";
var anchors = $(".anchor-target");

// Scroll to target
$(".anchor").click(function (e) {
  e.preventDefault();

  $(".anchor").removeClass("text-sm-orange");
  $(this).addClass("text-sm-orange");

  var target = $(this).attr("href");

  $("html, body").animate(
    {
      scrollTop: $(target).offset().top - 170,
    },
    300,
    "linear"
  );
});

// Update anchor on scroll
if ($(".anchor").length) {
  $(document).scroll(function () {
    var currentTop = window.pageYOffset / 1 + 180;

    for (var i = 0; i < anchors.length; i++) {
      var currentElementTop = $(anchors[i]).offset().top;
      var hash = $(anchors[i]).attr("id");

      if (
        currentElementTop < currentTop &&
        currentTop < currentElementTop + $(anchors[i]).height() &&
        currentHash != hash
      ) {
        if (history.pushState) {
          history.pushState(null, null, "#" + hash);
        } else {
          location.hash = "#" + hash;
        }

        $('.anchor[href!="#' + hash + '"]').removeClass("text-id-yellow");
        $('.anchor[href="#' + hash + '"]').addClass("text-id-yellow");

        currentHash = hash;
      }
    }
  });
}

// Filters
$(document).on("click", ".toggle", function () {
  var button = $(this);
  var target = button.data("target");
  var targetList = $('.list[data-list="' + target + '"]');
  var lists = $('.list:not(.list[data-list="' + target + '"])');

  if (lists.is(":visible")) {
    $(".toggle").removeClass("text-id-yellow");
    button.toggleClass("text-id-yellow");

    lists.slideToggle(function () {
      targetList.slideToggle();
    });
  } else {
    button.toggleClass("text-id-yellow");
    targetList.slideToggle();
  }
});

// Carousel
var swiper = new Swiper(".swiper", {
  slidesPerView: "auto",
  spaceBetween: 10,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});

// lazy loader
$(document).on("click", ".sprigFilter", function () {
  $("html, body").scrollTop(0);
  $("body > header, .subheader").removeClass("-translate-y-16");
  $(".no-subheader").removeClass("-translate-y-full");
});

htmx.on("htmx:load", function (e) {
  let thumbs = $(".item .group");
  var loader = $("#load-more")[0];

  if (thumbs.length) {
    thumbs.addClass("opacity-100");
  }

  if (loader && isInViewport(loader)) {
    loader.click();
  }
});

htmx.config.requestClass = "loading";

// Lazy load on scroll
let scrolledTo;

$(window).on("scroll", function () {
  var loader = $("#load-more")[0];

  if (loader && !scrolledTo && isInViewport(loader)) {
    scrolledTo = true;
    loader.click();

    setTimeout(function () {
      scrolledTo = false;
    }, 1000);
  }
});

function isInViewport(el) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight + 100 ||
        document.documentElement.clientHeight + 100) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
