import { openModal, closeModal } from "./modal.js";

export function openNav() {
  $("#main-navigation").removeClass("hidden");
  $("#hamburger .open").addClass("hidden");
  $("#hamburger .close").removeClass("hidden");
  closeSearch();
  openModal();
}

export function closeNav() {
  $("#main-navigation").addClass("hidden");
  $("#hamburger .close").addClass("hidden");
  $("#hamburger .open").removeClass("hidden");
  closeModal();
}

export function openSearch() {
  $(".search-results").removeClass("hidden");
  $(".search-results input").focus();
  $("#search .open").addClass("hidden");
  $("#search .close").removeClass("hidden");
  closeNav();
  openModal();
}

export function closeSearch() {
  $(".search-results").addClass("hidden");
  $("#search .close").addClass("hidden");
  $("#search .open").removeClass("hidden");
  closeModal();
}
