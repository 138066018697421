let map;

// Build map
async function initMap(target) {
  const lat = target.dataset.lat;
  const lng = target.dataset.lng;

  const position = { lat: parseFloat(lat), lng: parseFloat(lng) };
  // Request needed libraries.
  const { Map } = await google.maps.importLibrary("maps");

  // The map
  map = new Map(target, {
    zoom: 16,
    center: position,
    disableDefaultUI: true,
    // mapId: "DEMO_MAP_ID", // For cloud styling
    styles: [
      {
        featureType: "all",
        elementType: "geometry",
        stylers: [{ saturation: -100 }],
      },
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
    ],
  });

  // The marker
  const marker = new google.maps.Marker({
    map: map,
    position: position,
    icon: "../../brand/marker-s.png",
    title: "DesignID",
  });
}

// Look for all map containers
const buildMaps = window.addEventListener(
  "load",
  (event) => {
    document.querySelectorAll(".google-map").forEach(function (i) {
      if (i) {
        initMap(i);
      }
    });
  },
  false
);

export { buildMaps };
